import {
  createSystemPermission,
  deleteSystemPermission,
  queryInternalPermissionTree,
  updateSystemPermission,
} from '../services/internal';
import { getTreeDataCheckedAndExpandedKeys } from '../utils/tree';
import { querySystemPermissionTree } from '../services/permission';

const BASE = 'PERMISSION';

export const actions = {
  loadingSystemPermissions: `${BASE}_LOADING_SYSTEM_PERMISSION`,
  loadSystemPermissionsSuccess: `${BASE}_LOADING_SYSTEM_PERMISSION_SUCCESS`,
  loadingSystemPermissionsFailed: `${BASE}_LOADING_SYSTEM_PERMISSION_FAILED`,
};

export const fetchSystemPermissionTree = (reload) => async (dispatch, getState) => {
  if (!reload) {
    const { permissionTree } = getState().permission;
    if (permissionTree && permissionTree.length) return;
  }

  dispatch({
    type: actions.loadingSystemPermissions,
  });

  let response;
  try {
    response = await querySystemPermissionTree();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadingSystemPermissionsFailed,
    });
    return;
  }

  const permissionTree = response.data || [];
  const { checkedKeys, permissionMapping, permissionCodeToIdMapping } =
    getTreeDataCheckedAndExpandedKeys(permissionTree);
  const data = {
    permissionTree,
    defaultCheckedKeys: checkedKeys,
    permissionMapping,
    permissionCodeToIdMapping,
  };
  dispatch({
    type: actions.loadSystemPermissionsSuccess,
    payload: data,
  });
};

export const fetchPermissionTree = (authorization, callback) => async (dispatch) => {
  dispatch({
    type: actions.loadingSystemPermissions,
  });

  let response;

  try {
    response = await queryInternalPermissionTree(authorization);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadingSystemPermissionsFailed,
    });
    return;
  }

  const permissionTree = response.data || [];
  const { expandedKeys, checkedKeys, permissionMapping } = getTreeDataCheckedAndExpandedKeys(permissionTree);
  const data = {
    permissionTree,
    defaultExpandedKeys: expandedKeys,
    defaultCheckedKeys: checkedKeys,
    permissionMapping,
  };
  dispatch({
    type: actions.loadSystemPermissionsSuccess,
    payload: data,
  });
  typeof callback === 'function' && callback(data);
};

export const doCreatePermission = (data, callback) => async (dispatch) => {
  let response;

  try {
    response = await createSystemPermission(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doUpdatePermission = (data, callback) => async (dispatch) => {
  let response;

  try {
    response = await updateSystemPermission(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};

export const doDeletePermission = (id, authorization, callback) => async (dispatch) => {
  let response;

  try {
    response = await deleteSystemPermission(id, authorization);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);
};
