import { getAllRestaurants } from '../services/internal';

const BASE = 'RESTAURANTS';
const PARALLEL_REQUESTS = 5;

export const actions = {
  loadingRestaurants: `${BASE}_LOADING_RESTAURANTS`,
  loadRestaurantsSuccess: `${BASE}_LOAD_RESTAURANTS_SUCCESS`,
  loadRestaurantsFailed: `${BASE}_LOAD_RESTAURANTS_FAILED`,
};

const fetchRestaurantPageData = async ({ page = 1, distribution_modes, totalPageData, authorization }) => {
  let response;
  try {
    response = await getAllRestaurants({ page, distribution_modes, authorization });
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;
  const {
    data,
    meta: { pages },
  } = response;
  const { current_page, total_pages } = pages;
  if (!totalPageData) totalPageData = Array(total_pages).fill(null);
  totalPageData[page - 1] = data;

  if (current_page === 1 && total_pages > current_page) {
    const pageList = [[]];

    let i = 2;
    while (i <= total_pages) {
      pageList[pageList.length - 1].push(i);
      if ((i - 1) % PARALLEL_REQUESTS === 0) {
        pageList.push([]);
      }
      i++;
    }

    for await (const pages of pageList) {
      if (pages.length) {
        const promises = pages.map((page) => fetchRestaurantPageData({ distribution_modes, page, totalPageData }));
        await Promise.all(promises);
      }
    }
  }

  return { success: true, data: totalPageData };
};

export const fetchRestaurants = (payload) => async (dispatch, getState) => {
  const {
    restaurants: { restaurants },
  } = getState();
  if (restaurants.length) return;

  dispatch({ type: actions.loadingRestaurants });

  let response;

  try {
    response = await fetchRestaurantPageData(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadRestaurantsFailed });
    return;
  }

  const rawData = response.data || [];
  const allRestaurants = rawData.reduce((prev, current) => {
    if (current && current.length) prev.push(...current);
    return prev;
  }, []);

  dispatch({
    type: actions.loadRestaurantsSuccess,
    payload: allRestaurants,
  });
};
