import { queryAppConfigs } from 'src/services/appconfig';

const BASE = 'APP_CONFIG';

export const actions = {
  loadingAppConfigs: `${BASE}_LOADING_APP_CONFIGS`,
  loadAppConfigsSuccess: `${BASE}_LOAD_APP_CONFIGS_SUCCESS`,
  loadAppConfigsFailed: `${BASE}_LOAD_APP_CONFIGS_FAILED`,
};

export const fetchAppConfigs = (params, callback) => async (dispatch) => {
  dispatch({
    type: actions.loadingAppConfigs,
  });

  let response;

  try {
    response = await queryAppConfigs(params);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({
      type: actions.loadAppConfigsFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadAppConfigsSuccess,
    payload: { appConfigs: response.data },
  });
};
