import { DELETE, GET, POST, PUT } from 'src/utils/request';
import { API_PREFIX, CLIENT_ID } from 'src/consts';
import { BASIC_AUTH } from 'src/consts/third_party';
import { getReportServiceApiPrefix } from 'src/utils/report';

export function getEarningReport({ start_at, end_at, restaurant_id, order_sources, platforms }) {
  return GET(
    `${getReportServiceApiPrefix()}/admin/v2/earning/report`,
    {
      start_at,
      end_at,
      restaurant_id,
      order_sources,
      platforms,
    },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getCashFlowDailyReport(payload) {
  return GET(`${API_PREFIX}/admin/v1/cash_flow/report`, payload, {
    headers: {
      Authorization: `Basic ${BASIC_AUTH}`,
    },
    noNeedLogin: true,
  });
}

export function getRestaurantPayouts(payload) {
  return GET(`${getReportServiceApiPrefix()}/admin/v2/payout/report`, payload, {
    headers: {
      Authorization: `Basic ${BASIC_AUTH}`,
      'client-id': CLIENT_ID,
    },
    parseAsJSONApi: true,
    noNeedLogin: true,
  });
}

export function getDynamicRateFees(payload) {
  return GET(`${getReportServiceApiPrefix()}/admin/v2/earning/flaterate`, payload, {
    headers: {
      Authorization: `Basic ${BASIC_AUTH}`,
      'client-id': CLIENT_ID,
    },
    parseAsJSONApi: true,
    noNeedLogin: true,
  });
}

export function getRestaurantInfo(restaurantId) {
  return GET(
    `${API_PREFIX}/admin/v1/restaurant/${restaurantId}`,
    {},
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getRestaurantPreference(restaurant_id) {
  return GET(
    `${API_PREFIX}/admin/v2/preference`,
    { restaurant_id },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getRestaurantAllRefunds({ restaurant_id, time_from, time_to, page = 1 }) {
  return GET(
    `${API_PREFIX}/admin/v1/online/refunds/${restaurant_id}`,
    {
      time_from,
      time_to,
      page,
    },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
        'client-id': CLIENT_ID,
      },
      parseAsJSONApi: true,
      noNeedLogin: true,
    }
  );
}

export function getRestaurantAllPaymentTypes(restaurant_id) {
  return GET(
    `${API_PREFIX}/admin/v2/alternative_payments`,
    { restaurant_id },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getSummaryByPurchaseThenGroup({
  restaurant_id,
  time_from,
  time_to,
  product_line_name,
  display_group_name,
  distribution_mode,
  page = 1,
  page_size = 50,
}) {
  const apiPrefix = getReportServiceApiPrefix();
  return GET(
    `${apiPrefix}/admin/v2/charges/vendors/${restaurant_id}/summary/by_purchase_then_group`,
    {
      time_from,
      time_to,
      product_line_name,
      display_group_name,
      distribution_mode,
      page,
      page_size,
    },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      parseAsJSONApi: true,
      noNeedLogin: true,
    }
  );
}

export function getRestaurantSettings(restaurantId) {
  return GET(
    `${API_PREFIX}/admin/v1/setting`,
    { restaurant_id: restaurantId },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getCostPlusData(payload) {
  return GET(`${getReportServiceApiPrefix()}/admin/v2/earning/costplus`, payload, {
    headers: {
      Authorization: `Basic ${BASIC_AUTH}`,
    },
    noNeedLogin: true,
  });
}

export function getCutoffTimeByDate({ restaurant_id, date }) {
  return GET(
    `${getReportServiceApiPrefix()}/admin/v2/restaurant/cutoff_time`,
    { restaurant_id, date },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function queryInternalPermissionTree(authorization) {
  return GET(
    `${API_PREFIX}/admin/v1/permissions/tree`,
    {},
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
      noNeedLogin: true,
    }
  );
}

export function createSystemPermission({ authorization, ...data }) {
  return POST(`${API_PREFIX}/admin/v1/permission`, data, {
    headers: {
      Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
    },
    noNeedLogin: true,
  });
}

export function updateSystemPermission({ id, authorization, ...data }) {
  return PUT(`${API_PREFIX}/admin/v1/permission/${id}`, data, {
    headers: {
      Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
    },
    noNeedLogin: true,
  });
}

export function deleteSystemPermission(id, authorization) {
  return DELETE(
    `${API_PREFIX}/admin/v1/permission/${id}`,
    {},
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
      noNeedLogin: true,
    }
  );
}

export function assignPermissionsForOwners({ restaurant_ids, permission_id, authorization }) {
  return POST(
    `${API_PREFIX}/admin/v1/role/authorize/owner`,
    { restaurant_ids, permission_id },
    {
      headers: {
        Authorization: (!authorization.includes('Basic') ? 'Basic ' : '') + authorization,
      },
      noNeedLogin: true,
    }
  );
}

export function getAllRestaurants({ authorization, page = 1, distribution_modes }) {
  const auth = authorization || BASIC_AUTH;
  return GET(
    `${API_PREFIX}/admin/v2/restaurants`,
    {
      page,
      distribution_mode: distribution_modes,
    },
    {
      headers: {
        Authorization: (!auth.includes('Basic') ? 'Basic ' : '') + auth,
      },
      parseAsJSONApi: true,
      noNeedLogin: true,
    }
  );
}

export function getRestaurantSaleBreakdowns({ start_at, end_at, restaurant_id, order_sources }) {
  return GET(
    `${getReportServiceApiPrefix()}/admin/v1/earning/sales-breakdown`,
    { restaurant_id, start_at, end_at, order_sources },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getFileMappingByFileName(fileName) {
  return GET(
    `${API_PREFIX}/admin/v1/monthly-statement/google-drive/file-mapping`,
    {
      file_name: fileName,
    },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function deleteFileMappingByIds(ids) {
  return DELETE(
    `${API_PREFIX}/admin/v1/monthly-statement/google-drive/file-mapping/${ids}`,
    {},
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}

export function getAdminFeatureConfig(featureName, restaurantId) {
  return GET(
    `${API_PREFIX}/admin/v1/config/feature/${featureName}`,
    { restaurant_id: restaurantId },
    {
      headers: {
        Authorization: `Basic ${BASIC_AUTH}`,
      },
      noNeedLogin: true,
    }
  );
}
